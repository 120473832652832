import React ,{useState,useRef,useEffect} from 'react'
const RevealOnScroll = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
      const scrollObserver = new IntersectionObserver(([entry]) => {
          if (entry.isIntersecting) {
              setIsVisible(true);
              scrollObserver.unobserve(entry.target);
          }
      });

      scrollObserver.observe(ref.current);

      return () => {
          if (ref.current) {
              scrollObserver.unobserve(ref.current);
          }
      };
  },);

  const classes = `transition-opacity duration-1000
      ${isVisible ? "opacity-100" : "opacity-0"
      }`;

  return (
      <div ref={ref} className={classes}>
          {children}
      </div>
  );
};

function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
 
  return (
    <div className='relative pt-7'>
         
         <h1 className="align-center text-sky-600  pt-10 text-center w-screen  p-5  font-bold text-3xl ">
            Privacy Policy
         </h1>
         <RevealOnScroll>

         <div className=" w-screen p-10" >
          <p className="p-4 pb-0 text-lg font-sans font-bold">
           Who are we
        </p>
          <p className="p-4  pb-0 ml-4 text-lg font-sans">
          SMSCloud Hub (“us”, “we”, or “our”) operates various products and services including CloudSkool++, Cloud Contact Center, and messaging services (SMS, WhatsApp, Google RCS). This Privacy Policy outlines our practices regarding the collection, use, and disclosure of Personal Information across all our products and services.      </p>
          <p className="p-4  pb-0 font-bold text-lg font-sans">
          Information Collection and Use </p>
          <p className="p-4  pb-0 ml-4 text-lg font-sans">
          We collect and use Personal Information to provide and improve our services. Personal Information may include, but is not limited to, your name, contact details, and other information relevant to the use of our services.    </p>
          <p className="p-4 font-bold pb-0  text-lg font-sans">
          CloudSkool++  </p>
          <p className="p-4 ml-4 pb-0  text-lg font-sans">
          For CloudSkool++, we use your Personal Information to communicate updates related to school and manage school records. We ensure that this information is kept confidential and not shared with anyone outside the school. We advise users to keep their login credentials secure and change their passwords regularly.    </p>
           <p className="p-4 pb-0  text-xl font-sans font-bold">
           Cloud Contact Center </p> 
           <p className="p-4 ml-4 pb-0  text-lg font-sans">
           For our Cloud Contact Center services, we may collect and process communication data to manage and enhance our contact center solutions. This includes information related to call logs, messages, and other communication data. We use this information to improve our services and ensure effective communication. </p>
          <p className="p-4 pb-0  text-xl font-sans font-bold">
          Messaging Services (SMS, WhatsApp, Google RCS)  </p>
           <p className="p-4 ml-4 pb-0  text-lg font-sans">
           In providing messaging services, we collect and process data related to messages sent and received. This data is used to deliver messages, manage communication channels, and enhance our messaging services. We do not share this information with third parties without consent.</p> 
          <p className="p-4 pb-0 text-xl font-bold font-sans">
          Security
          </p>
          <p className="p-4 pb-0 ml-4 text-xl font-sans">
          The security of your Personal Information is paramount to us. While we use commercially acceptable means to protect your information, please be aware that no method of transmission over the Internet or electronic storage is completely secure. We cannot guarantee absolute security but strive to ensure your data is protected. </p>
           <p className="p-4 pb-0 text-xl font-bold font-sans">
           Log Data
          </p>
        
          <p className="p-4 pb-0 ml-4 text-xl font-sans">
          We use APIs to integrate various functionalities such as tracking services and data management. These APIs may access certain data but are designed to ensure that no personal information is shared with unauthorized parties. </p>
         
        
         
          <p className="p-4 font-bold pb-0 text-xl font-sans">
          APIs
          </p>
          <p className="p-4 pb-0 ml-4 text-xl font-sans">
          We use APIs to integrate various functionalities such as tracking services and data management. These APIs may access certain data but are designed to ensure that no personal information is shared with unauthorized parties. </p>
          <p className="p-4 font-bold pb-0 text-xl font-sans">
          Cookies
          </p>
          <p className="p-4 pb-0 ml-4 text-xl font-sans">
          Cookies are small files with data, including an anonymous unique identifier, sent to your browser from a web Mobile App and stored on your device. We use cookies to enhance your experience and analyze usage patterns. You can choose to disable cookies through your browser settings, but this may affect your experience with our services.  </p>
          <p className="p-4 font-bold pb-0 text-xl font-sans">
          Changes to This Privacy Policy
          </p>
          <p className="p-4 pb-0 ml-4 text-xl font-sans">
          This Privacy Policy is effective as of 01/01/2023 and will remain in effect except for any changes in its provisions. We may update this policy from time to time, and any changes will be posted on this page. Your continued use of our services after modifications signifies your acceptance of the updated policy.  </p>
          <p className="p-4 font-bold pb-0 text-xl font-sans">
          Contact Us   </p>
          <p className="p-4 pb-0 ml-4 text-xl font-sans">
          If you have any questions or concerns about this Privacy Policy, please contact us at info@smscloudhub.com.  </p>
          
        </div>
        </RevealOnScroll>
        
    </div>
  )
}

export default Privacy
